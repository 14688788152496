import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import filesFolderSrc from '../images/files-folder.svg';
import calculatorSrc from '../images/calculator.svg';
import calendarDotSrc from '../images/calendar-dot.svg';
import multiDeviceSrc from '../images/multi-device.png';

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import {AbaxElectricalTestimonial} from '../components/testimonial';


const DownloadOption = ({name, link}) => {
	return (
		<div className="flex cursor-pointer">
			<span className="text-lg font-bold text-highlight-600 hover:underline"><a target="_blank" href={link} rel="noreferrer noopener">{name} </a> </span>
		</div>
	)
}


const TimesheetTemplates = () => (
  <Layout>
    <SEO
    	title="Free Weekly and Monthly Employee Timesheet Templates in Microsoft Excel, Google Sheets and PDF"
    	description="Weekly and Monthly Free Downloadable and Printable Timesheet Templates in PDF, Excel and Google Sheets."
    	 />
    <Navbar />
    <div className="bg-gray-50">
	  	<div className="relative bg-white overflow-hidden">
		  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
		    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
		      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
		        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
		          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
		            Free Downloadable and Printable <span className="text-highlight-600">Timesheet Templates</span>
		          </h2>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		             Need a fancy looking printable timesheet template for your employees? Or are you looking for Excel or Google Sheets templates with ready made formulas? Well we have you covered!
		          </p>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		            Download our free timesheet templates with formulas included in Microsoft Excel, Google Sheets and PDF, to help you track time accurately for your employees.
		          </p>
		        </div>
		        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
		          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
		            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
		              <img className="w-full" src={filesFolderSrc} alt="Man downloading timesheet templates" />
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="py-8 bg-gray-50 overflow-hidden lg:py-16">
		  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
			    <LeftFeature
			    	sectionTitle="Weekly Paid Employees"
			    	title="Weekly Timesheet Template"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Our weekly timesheet template allows an employee to enter their clock in and out times for the duration of their working week. Their break time can also be specified for each day, and the formulas in our Excel or Google Sheet timesheet template will automatically deduct this from their hours.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	Simply enter in their hourly pay rate on the Excel or Google Sheet to automatically calculate their gross pay for the week too.
					        </FeaturePararaph>
					        <div className="flex flex-start text-gray-500 mt-4"><span className="italic">Click template below to download.</span></div>
					        <div className="flex flex-row justify-between items-center mt-4">
					        	<DownloadOption name="Microsoft Excel" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/templates/timekeeper-weekly-timesheet-template-2.xlsx" />
					        	<DownloadOption name="Google Sheets" link="https://docs.google.com/spreadsheets/d/1W_muJZpeGzY6MIgpbKiORa-FSW3rOMdSl_NxOlzpsTU/edit?usp=sharing" />
					        	<DownloadOption name="PDF" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/templates/timekeeper-weekly-timesheet-template-2.pdf" />
					        </div>

					    </div>
			    	}
			    	imgSrc={calculatorSrc}
			    	imgAlt="Weekly Timesheet Template Calculator"
			    />
			    <RightFeature
			    	sectionTitle="Monthly Paid Employees"
			    	title="Monthly Timesheet Template"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					        	Our monthly timesheet template allows you to enter in a full month’s worth of clock in and out times for an employee. Break times can also be entered for each day, and automatically deducted from the employee’s hours worked.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	An hourly pay rate can be set too on our Excel and Google Sheet templates, in order to calculate their estimated pay for the period.
					        </FeaturePararaph>
					        <div className="flex flex-start text-gray-500 mt-4"><span className="italic">Click template below to download.</span></div>
					        <div className="flex flex-row justify-between items-center mt-4">
					        	<DownloadOption name="Microsoft Excel" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/templates/timekeeper-monthly-timesheet-template-2.xlsx" />
					        	<DownloadOption name="Google Sheets" link="https://docs.google.com/spreadsheets/d/184_KifbB1WTMkYS5_IN39O0lmjKI8i2X9MFUBlAl9hM/edit?usp=sharing" />
					        </div>

					    </div>
			    	}
			    	imgSrc={calendarDotSrc}
			    	imgAlt="Weekly Timesheet Template Calculator"
			    />
			    <LeftFeature
			    	sectionTitle="Cloud Time and Attendance Software"
			    	title="How about no manual timesheets ever again?"
			    	body={
			    		<div>
					        <FeaturePararaph>
					          You might be using timesheet templates because they are convenient to jot hours information down. They aren't so convenient when it comes to making sure all the timesheets come in on time, or dealing with queries like 'how many hours have I worked this week' or even just making sure all the calculations add up.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	TimeKeeper is built to solve these problems. You can move off manual timesheets to a <span className="bold-font">simple</span> time and attendance system in less than 1 hour. 1 hour away from never looking at a manual timesheet again!
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	Why don't you sign up for a free 14 day trial or watch a demo below to find out more!
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={multiDeviceSrc}
			    	imgAlt="Move to Cloud Based Time and Attendance Software"
			    />
			</div>
			<AbaxElectricalTestimonial />
			<FreeTrial showDemoBooking={true} part1={<span>Get rid of <span className="text-highlight-600">manual timesheets</span> <br /> in your small business today!</span>} />
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default TimesheetTemplates;
